module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/var/lib/jenkins/workspace/Conflux-Dev_building-website_dev/src/intl","languages":["en","zh"],"defaultLanguage":"zh","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Building website","short_name":"Building","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"76ab67a45c7d19e5438fe14a16ab8735"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
