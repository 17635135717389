// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-book-index-js": () => import("./../src/pages/contact-book/index.js" /* webpackChunkName: "component---src-pages-contact-book-index-js" */),
  "component---src-pages-contract-create-index-js": () => import("./../src/pages/contract-create/index.js" /* webpackChunkName: "component---src-pages-contract-create-index-js" */),
  "component---src-pages-contract-view-index-js": () => import("./../src/pages/contract-view/index.js" /* webpackChunkName: "component---src-pages-contract-view-index-js" */),
  "component---src-pages-external-management-index-js": () => import("./../src/pages/external-management/index.js" /* webpackChunkName: "component---src-pages-external-management-index-js" */),
  "component---src-pages-home-index-js": () => import("./../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-management-index-js": () => import("./../src/pages/info-management/index.js" /* webpackChunkName: "component---src-pages-info-management-index-js" */),
  "component---src-pages-storage-management-index-js": () => import("./../src/pages/storage-management/index.js" /* webpackChunkName: "component---src-pages-storage-management-index-js" */),
  "component---src-pages-submit-stage-update-index-js": () => import("./../src/pages/submit-stage-update/index.js" /* webpackChunkName: "component---src-pages-submit-stage-update-index-js" */),
  "component---src-pages-system-setting-index-js": () => import("./../src/pages/system-setting/index.js" /* webpackChunkName: "component---src-pages-system-setting-index-js" */),
  "component---src-pages-view-stage-update-index-js": () => import("./../src/pages/view-stage-update/index.js" /* webpackChunkName: "component---src-pages-view-stage-update-index-js" */)
}

